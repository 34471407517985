import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Hack Guard`, `Cybersecurity`, `Sudan`, `about us`]}
        title="About"
      />

      <section className="flex flex-col items-center md:flex-row">
        <div className="md:w-2/3 md:mr-8">
          <h1 className="p-4 mb-4 border-b-4 border-teal-700 text-bold text-3xl">
            Hack Guard
          </h1>
          <p className="pl-4 font-serif leading-loose text-justify border-l-4 border-teal-700">
            Founded in Khartoum, Sudan, Hack Guard was created to fill a gap in
            the local cybersecurity industry. We provide local and regional
            enterprises access to a suite of modern cybersecurity services with
            cost-effectivness and efficiency top of mind. By identifying and
            iliminating cyber threats, we assist in providing a sense of
            security and control in your environment.
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
